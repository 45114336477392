import styled from 'styled-components';
import { useTranslation } from '@hooks';
import Link from 'next/link';

import { FooterItem } from '@components/common';
import { EnglishFlagIcon, LockIcon, TurkishFlagIcon } from '@components/icons';
import { Divider, StyledColumn } from '@components/styles/CommonStyles';
import { Caption, Caption2, Text3 } from '@components/styles/Typography';
import {
    footerExplore,
    footerFollow,
    footerHelp,
    footerKeiKei,
} from 'lib/footerItems';
import { useModal } from '@context/ModalContext';
import LogoMini from '../Logo/LogoMini';

const Footer = ({ filled }) => {
    const { t, locale } = useTranslation();
    const { openLangModal } = useModal();

    return (
        <FooterWrapper data-testid="footer">
            <FooterContainer>
                <List>
                    <li>
                        <StyledColumn width="100%">
                            <LogoMini />
                        </StyledColumn>
                    </li>
                </List>
                <FooterSubcontainer>
                    <List>
                        <li>
                            <Caption2>{t('footerKeiKei')}</Caption2>
                        </li>
                        {footerKeiKei.map((footerKeiKei) => (
                            <li key={footerKeiKei.slug.en}>
                                <FooterItem
                                    slug={footerKeiKei.slug[locale]}
                                    name={footerKeiKei.name[locale]}
                                />
                            </li>
                        ))}
                    </List>
                    <List>
                        <li>
                            <Caption2>{t('footerExplore')}</Caption2>
                        </li>
                        {footerExplore.map((footerExplore) => (
                            <li key={footerExplore.name[locale]}>
                                <FooterItem
                                    slug={footerExplore.slug[locale]}
                                    name={footerExplore.name[locale]}
                                />
                            </li>
                        ))}
                    </List>
                    <List>
                        <li>
                            <Caption2>{t('footerSocialMedia')}</Caption2>
                        </li>
                        {footerFollow.map((footerFollow) => (
                            <li key={footerFollow.slug.en}>
                                <Link href={footerFollow.slug[locale]} passHref>
                                    <Caption2>
                                        {footerFollow.name[locale]}
                                    </Caption2>
                                </Link>
                            </li>
                        ))}
                    </List>
                </FooterSubcontainer>
                <List>
                    <li>
                        <Caption2>{t('footerHelp')}</Caption2>
                    </li>
                    {footerHelp.map((footerHelp) => (
                        <li key={footerHelp.slug.en}>
                            <FooterItem
                                slug={footerHelp.slug[locale]}
                                name={footerHelp.name[locale]}
                            />
                        </li>
                    ))}
                </List>
                <List>
                    <li>
                        <Caption2>{t('choice')}</Caption2>
                    </li>
                    <li>
                        <LangModalOpenerContainer>
                            {locale === 'en' ? (
                                <FooterLangModalOpener>
                                    <EnglishFlagIcon />
                                    <Caption2> English</Caption2>
                                </FooterLangModalOpener>
                            ) : (
                                <FooterLangModalOpener>
                                    <TurkishFlagIcon />
                                    <Caption2> Türkçe</Caption2>
                                </FooterLangModalOpener>
                            )}
                            <Divider />
                            <Caption
                                fontFamily="Futura Now Headline Medium"
                                onClick={openLangModal}
                            >
                                {t('change')}
                            </Caption>
                        </LangModalOpenerContainer>
                    </li>
                </List>
            </FooterContainer>
            <FooterRightsText desktopHidden>
                <LockIcon filled={filled} /> {t('footerSecureShopping')}
            </FooterRightsText>
            <Text3
                style={{
                    textAlign: 'center',
                }}
            >
                {t('footerAllRightsReserved')}
            </Text3>
        </FooterWrapper>
    );
};
const FooterWrapper = styled.footer`
    background-color: ${({ theme }) => theme.colors.secondary};
    padding: 50px 0 25px;

    @media (max-width: 1024px) {
        flex-direction: column;
        padding: 20px 30px;
    }
    @media (max-width: 768px) {
        margin-bottom: 50px;
    }
`;

const FooterContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 768px) {
        max-width: 500px;
        flex-direction: column;
    }
`;

const FooterSubcontainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;

    svg {
        margin-top: 10px;
    }

    li {
    }

    @media (max-width: 1024px) {
        align-items: flex-start;
    }
`;

const List = styled.ul`
    max-width: 160px;
    height: 120px;
    padding: 0;
    width: 100%;
    @media (max-width: 1024px) {
        height: 150px;
    }
    @media (max-width: 768px) {
        height: auto;
    }

    li {
        width: auto;
        margin-right: 20px;
        margin-bottom: 5px;
        @media (max-width: 768px) {
            margin-right: 0;
        }

        :first-child {
            margin-bottom: 10px;
            @media (max-width: 768px) {
                margin-bottom: 10px;
            }
        }
    }

    @media (max-width: 1024px) {
        min-height: 0;
        margin: 10px 0;
        width: ${({ Width }) => Width || '100%'};
        li {
            text-align: center;
            white-space: nowrap;
        }
    }
    @media (max-width: 768px) {
        max-width: none;
    }
`;
const FooterRightsText = styled(Text3)`
    display: ${({ desktopHidden }) => (desktopHidden && 'none') || 'block'};
    text-align: center;

    @media (max-width: 768px) {
        display: block;
    }
`;

const LangModalOpenerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;

    span {
        cursor: pointer;
    }

    @media (max-width: 768px) {
        justify-content: center;
    }
`;

const FooterLangModalOpener = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    svg {
        margin-right: 5px;
    }
`;
export default Footer;
