function EnglishFlagIcon(props) {
    return (
        <svg
            width="24px"
            height="16px"
            viewBox="0 0 24 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>flag-English</title>
            <defs>
                <path
                    d="M2.18057143,0 L21.8194286,0 C23.0237249,0 24,0.969351146 24,2.16510638 L24,13.8348936 C24,15.0306489 23.0237249,16 21.8194286,16 L2.18057143,16 C0.976275083,16 0,15.0306489 0,13.8348936 L0,2.16510638 C0,0.969351146 0.976275083,0 2.18057143,0 Z"
                    id="path-1"
                ></path>
            </defs>
            <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
            >
                <g id="flag-English">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1"></use>
                    </mask>
                    <use
                        id="Mask"
                        fill="#E60000"
                        fill-rule="nonzero"
                        xlinkHref="#path-1"
                    ></use>
                    <g
                        id="tobias-Flag-of-the-United-Kingdom"
                        mask="url(#mask-2)"
                    >
                        <g transform="translate(-4.000000, -0.000000)">
                            <rect
                                id="rect124"
                                fill="#000066"
                                fill-rule="nonzero"
                                x="0"
                                y="0"
                                width="31.9998933"
                                height="15.9999467"
                            ></rect>
                            <g
                                id="g584"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                            >
                                <path
                                    d="M-9.47387156e-16,0 L-9.47387156e-16,1.78884737 L28.4223053,16 L31.9998933,16 L31.9998933,14.211206 L3.57758807,0 L-9.47387156e-16,0 L-9.47387156e-16,0 Z M31.9998933,0 L31.9998933,1.78879404 L3.57758807,15.9999467 L-9.47387156e-16,15.9999467 L-9.47387156e-16,14.2111526 L28.4223053,0 L31.9998933,0 Z"
                                    id="path146"
                                    fill="#FFFFFF"
                                    fill-rule="nonzero"
                                ></path>
                                <path
                                    d="M13.3332889,0 L13.3332889,15.9999467 L18.6666044,15.9999467 L18.6666044,0 L13.3332889,0 Z M0,5.33331556 L0,10.6666311 L31.9998933,10.6666311 L31.9998933,5.33331556 L0,5.33331556 Z"
                                    id="path136"
                                    fill="#FFFFFF"
                                    fill-rule="nonzero"
                                ></path>
                                <path
                                    d="M0,6.39997867 L0,9.599968 L31.9998933,9.599968 L31.9998933,6.39997867 L0,6.39997867 Z M14.399952,0 L14.399952,15.9999467 L17.5999413,15.9999467 L17.5999413,0 L14.399952,0 Z"
                                    id="path141"
                                    fill="#CC0000"
                                    fill-rule="nonzero"
                                ></path>
                                <path
                                    d="M-5.92087367e-17,15.9999467 L10.6666311,10.6666311 L13.0516898,10.6666311 L2.38505872,15.9999467 L-5.92087367e-17,15.9999467 Z M-9.47387156e-16,0 L10.6666311,5.33331556 L8.28157239,5.33331556 L-9.47387156e-16,1.19258269 L-9.47387156e-16,0 Z M18.9482035,5.33331556 L29.6148346,0 L31.9998933,0 L21.3332622,5.33331556 L18.9482035,5.33331556 Z M31.9998933,15.9999467 L21.3332622,10.6666311 L23.7183209,10.6666311 L31.9998933,14.8074173 L31.9998933,15.9999467 Z"
                                    id="path150"
                                    fill="#CC0000"
                                    fill-rule="nonzero"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default EnglishFlagIcon;
