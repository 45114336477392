import styled from 'styled-components';
import Link from 'next/link';

const LogoMini = () => (
    <LogoMiniContainer>
        <Link href="/" to="/" passHref>
            <StyledLogoMini
                src="https://cloud.keikei.com/cdn/banners/logomark1.png"
                alt="KeiKei"
                height="72px"
                loading="lazy"
            />
        </Link>
    </LogoMiniContainer>
);

const LogoMiniContainer = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: 1024px) {
        justify-content: center;
    }
`;

const StyledLogoMini = styled.img`
    width: 54px;
    margin-top: 10px;
    @media (max-width: 1024px) {
        width: 25px;
        height: 30px;
        object-fit: cover;
        margin-top: unset;
    }
`;

export default LogoMini;
