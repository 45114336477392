function LockIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={10}
            height={13}
            {...props}
        >
            <path
                d="M8.564 4.456h-.433V3.131C8.131 1.404 6.726 0 5.001 0S1.869 1.405 1.869 3.131v1.325h-.433A1.44 1.44 0 000 5.892v5.457a1.44 1.44 0 001.436 1.436h7.128A1.44 1.44 0 0010 11.349V5.892a1.44 1.44 0 00-1.436-1.436zM5.065 7a1.355 1.355 0 110 2.71 1.355 1.355 0 010-2.71zM5 1.276a1.86 1.86 0 011.855 1.855v1.325h-3.71V3.131A1.86 1.86 0 015 1.276z"
                fill={props.filled ? "#000" : "#FF4747"}
            />
        </svg>
    );
}

export default LockIcon;
