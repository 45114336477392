function TurkishFlagIcon(props) {
    return (
        <svg
            width="20"
            height="20"
            {...props}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill="none" fillRule="evenodd">
                <path
                    d="M1.817 3.333h16.366A1.81 1.81 0 0 1 20 5.138v9.724a1.81 1.81 0 0 1-1.817 1.805H1.817A1.81 1.81 0 0 1 0 14.862V5.138a1.81 1.81 0 0 1 1.817-1.805zm8.112 9.54h-.015a3.225 3.225 0 0 1-.5.194c-1.648.486-3.38-.446-3.871-2.083l-.017-.056a3.187 3.187 0 0 1 .82-3.18 3.242 3.242 0 0 1 3.208-.792c.169.055.332.124.489.207h.014a.109.109 0 0 0 .131-.053.107.107 0 0 0-.034-.137A3.864 3.864 0 0 0 8.8 6.204a4.04 4.04 0 0 0-3.536.572 3.98 3.98 0 0 0-1.521 4.316 3.905 3.905 0 0 0 2.601 2.667 3.942 3.942 0 0 0 3.67-.712.107.107 0 0 0 .036-.131.109.109 0 0 0-.121-.062v.02zm2.934-1.973 1.263.394-.73-1.097.768-1.07-1.276.35-.788-1.056-.059 1.313-1.255.418 1.24.462.012 1.314.825-1.028z"
                    fill="#E60000"
                />
                <path
                    d="M9.929 12.874h-.015a3.225 3.225 0 0 1-.5.193c-1.648.486-3.38-.446-3.871-2.083l-.017-.056a3.187 3.187 0 0 1 .82-3.18 3.242 3.242 0 0 1 3.208-.792c.169.055.332.124.489.207h.014a.109.109 0 0 0 .131-.053.107.107 0 0 0-.034-.137A3.864 3.864 0 0 0 8.8 6.204a4.04 4.04 0 0 0-3.536.572 3.98 3.98 0 0 0-1.521 4.316 3.905 3.905 0 0 0 2.601 2.667 3.942 3.942 0 0 0 3.67-.712.107.107 0 0 0 .036-.131.109.109 0 0 0-.121-.062v.02zM12.863 10.9l1.263.394-.73-1.097.768-1.07-1.276.35-.788-1.056-.059 1.313-1.255.418 1.24.462.012 1.314z"
                    fill="#FFF"
                />
            </g>
        </svg>
    );
}

export default TurkishFlagIcon;
