import Link from 'next/link';

import { Caption2 } from '@components/styles/Typography';

const FooterItem = ({ slug, name }) => (
    <Link to={slug} href={slug} passHref prefetch={false}>
        <Caption2>{name}</Caption2>
    </Link>
);

export default FooterItem;
